<template>
  <div class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <template>
        <eden-form-section>
          <template slot="form-fields">
            <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
              <el-col :md="24" class="is-flex is-flex-column align-center">
                <eden-image-upload
                  :image-url.sync="form.image"
                  :image-text="'meal image'"
                  :image-folder="'services/meal'"
                />
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          title="Meal Item Details"
          subtitle="Enter basic information about meal item."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Item name"
                  prop="itemName"
                  :rules="validateField()"
                >
                  <el-input
                    type="text"
                    v-model="form.itemName"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item
                  label="Meal category"
                  prop="category"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.category"
                    :multiple="false"
                    filterable
                    placeholder="Select this meal's category"
                  >
                    <el-option
                      v-for="(category, index) in mealClassCategories"
                      :key="index"
                      :label="category.label"
                      :value="category.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-if="false">
              <el-col :span="24">
                <el-form-item
                  label="Price of item"
                  prop="price"
                  :rules="validateField()"
                >
                  <el-input
                    min="1"
                    type="number"
                    v-model="form.price"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Available quantity"
                  prop="quantity"
                  :rules="validateField()"
                >
                  <el-input
                    min="1"
                    type="number"
                    v-model="form.quantity"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>

        <eden-form-section title="" subtitle="">
          <template slot="form-fields">
            <div class="eden-page-form__actions">
              <el-button plain @click="cancel">Cancel</el-button>

              <template>
                <el-button
                  v-if="action === 'add'"
                  type="primary"
                  :loading="adding"
                  @click="add"
                  >Add meal item
                </el-button>
                <el-button
                  v-else
                  type="primary"
                  :loading="updating"
                  @click="update"
                  >Save changes
                </el-button>
              </template>
            </div>
          </template>
        </eden-form-section>
      </template>
    </el-form>
  </div>
</template>

<script>
import mealItems from "@/requests/dance-festival/menu.js";

export default {
  props: {
    action: {
      type: String,
      default: "add",
    },
    mealItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      mealClassCategories: [
        {
          label: "Brunch - 11:00am - 1:00pm",
          value: "brunch",
          id: 1,
        },
        {
          label: "Lunch - 3:00pm - 5:00pm",
          value: "lunch",
          id: 2,
        },
        {
          label: "Dinner - 7:00pm - 9:00pm",
          value: "dinner",
          id: 3,
        },
      ],
      form: {
        image: "",
        itemName: "",
        category: "",
        quantity: "",
        price: "",
      },
      adding: false,
      updating: false,
    };
  },
  created() {},
  mounted() {
    if (this.mealItem && this.action == "edit") {
      this.matchForm(this.mealItem);
    }
  },
  methods: {
    cancel() {
      this.form.image = "";
      this.form.itemName = "";
      this.form.category = "";
      this.form.quantity = "";
      this.form.price = "";
      this.$router.replace({ name: "dance-festival.df-menu.index" });
    },
    add() {
      this.adding = true;

      const payload = {
        full_name: this.form.itemName,
        quantity: this.form.quantity,
        category_id: this.form.category,
        image_url: this.form.image,
      };

      mealItems
        .add(payload)
        .then(({ data }) => {
          console.log(data);

          if (data.status) {
            this.$message.success(data.message);
            this.cancel();
          }
          this.adding = false;
        })
        .catch((error) => {
          this.adding = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    update() {
      this.updating = true;

      const payload = {
        full_name: this.form.itemName,
        quantity: this.form.quantity,
        category_id: this.form.category,
        image_url: this.form.image,
      };

      mealItems
        .update(this.mealItem.id, payload)
        .then(({ data }) => {
          console.log(data);
          if (data.status) {
            this.$message.success(data.message);
            this.cancel();
          }
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    matchForm(item) {
      this.form.image = item.image_url || "";
      this.form.itemName = item.name;
      this.form.category = item.category_id;
      this.form.quantity = item.quantity;
      this.form.price = item.price;
    },
  },
  watch: {
    mealItem(v) {
      if (this.action === "edit" && v) {
        this.matchForm(v);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ingredient-add,
.combo-add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--eden-green-senary);
    border-radius: 10px;
    margin-right: 5px;

    i {
      color: var(--eden-green-primary);
      font-size: 0.75rem;
      font-weight: 60;
    }
  }

  p {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }
}

.ingredient-remove {
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.combo-empty {
  width: 50%;
  margin: 0 auto;
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  .combo-add {
    margin-top: 0;
  }
}
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>
