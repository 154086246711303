import axios from "axios";

export default {
  list(params = "") {
    return axios.get(`festival/menu${params}`);
  },

  summary(start_date, end_date) {
    return axios.get(
      `festival/menu/summary?start_date=${start_date}&end_date=${end_date}`,
    );
  },

  search(payload) {
    return axios.get(`festival/menu/search?search=${payload}`);
  },

  add(payload) {
    return axios.post("festival/menu", payload);
  },

  update(id, payload) {
    return axios.patch(`festival/menu/${id}`, payload);
  },

  get(id) {
    return axios.get(`festival/menu/${id}`);
  },

  remove(ids) {
    return axios.delete(`festival/menu?ids=[${[...ids]}]`);
  },
};
